// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-formularz-wyslany-js": () => import("./../../../src/pages/formularz-wyslany.js" /* webpackChunkName: "component---src-pages-formularz-wyslany-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mosty-js": () => import("./../../../src/pages/mosty.js" /* webpackChunkName: "component---src-pages-mosty-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-psychoterapia-szkoleniowa-js": () => import("./../../../src/pages/psychoterapia-szkoleniowa.js" /* webpackChunkName: "component---src-pages-psychoterapia-szkoleniowa-js" */),
  "component---src-pages-seminaria-teoretyczno-superwizyjne-js": () => import("./../../../src/pages/seminaria-teoretyczno-superwizyjne.js" /* webpackChunkName: "component---src-pages-seminaria-teoretyczno-superwizyjne-js" */)
}

